// 主题色1
@brand1-1: #e6f7ff;
@brand1-6: #0c85ee;
@brand1-8: #004ba1;

// 主题色1
@brand2-1: #e6ffee;
@brand2-6: #03c666;
@brand2-1: #00a156;

// 线条颜色
@line1-1: #dfe5ed;
@line1-2: #d7dce8;
@line1-3: #b7becd;
@line1-4: #747f93;

@fill1-1: #f1f3f7;
@fill1-2: #eef1f4;
@fill1-3: #e2e7ee;
@fill1-4: #cbd2de;

// 背景色
@pannel1-1: #f1f3f7;
@pannel1-2: #eef1f4;
@pannel1-3: #e2e7ee;
@pannel1-4: #cbd2de;

// 文字颜色
@text1-1: #b7becb;
@text1-2: #747f93;
@text1-3: #454e61;
@text1-4: #2a2e3c;

// 功能色 用于信息提示
@notice1-1: #e6f7ff;
@notice1-2: #b0e5ff;
@notice1-3: #0c85ee;
@notice1-4: #004ba1;

// 功能色 用于表示成功
@success1-1: #e6ffee;
@success1-2: #a5fac4;
@success1-3: #03c666;
@success1-4: #007a45;
// 功能色 用于表示警告
@warning1-1: #fff9e6;
@warning1-2: #ffe5a3;
@warning1-3: #ff9500;
@warning1-4: #b35c00;

// 功能色 用于表示错误
@error1-1: #fff2f0;
@error1-2: #ffd1c9;
@error1-3: #f12525;
@error1-4: #a60813;

// 数据色
@data1-1: #ff3d00;
@data1-2: #ff9100;
@data1-3: #ffea00;
@data1-4: #1cda7c;
@data1-5: #00b0ff;
@data1-6: #2979ff;
@data1-7: #651fff;
@data1-8: #d500f9;

// 系统色： 黑 白 透明
@black: #000000;
@white: #ffffff;
@transparent: transparent;

// corner 圆角
@corner-1: 4px;
@corner-2: 8px;
@corner-3: 16px;

// 字体
@font-family: Roboto, 'Helvetica Neue', Helvetica, Tahoma, Arial, 'PingFang SC', 'Microsoft YaHei';

// 行高
@line-height-1: 1.3;
@Line-height-2: 1.5;
@Line-height-3: 1.7;

// 字体尺寸
@Display-3: 64px; // line-height-1（86px）
@Display-2: 56px; // line-height-1（72px）
@Display-1: 36px; // line-height-1（46px）
@Headline: 24px; // line-height-1（32px）
@Title: 20px; // line-height-1（26px）
@Subhead: 16px; // line-height-1（22px）
@Body-1: 14px; // line-height-2（22px）
@Body-2: 12px; // line-height-2（18px）
@Caption: 10px; // line-height-2（15px）

// 粗细
@Bold: bold;
@Regular: normal;
@Light: lighter;

// Shadow 阴影
@Shadow-1-RB: 1px 1px 3px 0 rgba(42, 46, 60, 0.12);
@Shadow-2-RB: 2px 2px 4px 0 rgba(42, 46, 60, 0.12);
@Shadow-3-RB: 3px 3px 5px 0 rgba(42, 46, 60, 0.12);
@Shadow-1-B: 0 1px 3px 0 rgba(42, 46, 60, 0.12);
@Shadow-2-B: 0 2px 4px 0 rgba(42, 46, 60, 0.12);
@Shadow-3-B: 0 3px 5px 0 rgba(42, 46, 60, 0.12);

// Size 尺寸
@base-size: 4px;

// 间距倍数
@space: 4;

@link-color: rgb(12, 133, 238);

@list-hover-color: #fafafa;
@title-font-size: 14px;
.dashboard-layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  .ant-spin-nested-loading {
    margin-top: 80px;
  }
  .ant-layout-content {
    margin-left: 200px;
    transition: all 0.3s linear;
  }
  .ant-layout-sider {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    margin-bottom: 0;
    margin-top: 40px;
  }
  .collapsed-side {
    margin-left: 40px;
  }
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0;
    display: inline-block;
    .anticon {
      font-size: 18px !important;
    }
  }
  .navtabs-container {
    position: fixed;
    left: 200px;
    top: 4px;
    z-index: 100;
    margin-top: 40px;
    transition: all 0.3s linear;
    right: 0;
    &--collapsed {
      left: 40px;
    }
    .ant-tabs {
      margin-left: 16px;
      margin-right: 16px;
      // margin-top: 40px;
      .ant-tabs-nav {
        margin-bottom: 0;
        &::before {
          border-bottom: none;
        }
      }
    }
  }
}
.dashboard-sider {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
  }
}

.dashboard-sider.ant-layout-sider {
  padding: 0;
}
.nav-container {
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0 0 18px rgb(0 0 0 / 6%);
  height: 40px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  align-items: center;
  // box-shadow: 0 2px 4px 0 rgba(107, 80, 80, 0.16);
  box-sizing: border-box;
  .nav-left {
    display: flex;
    align-items: center;
    width: 200px;
    border-right: 1px solid #f1f1f1;
    &--close {
      background-color: #ff6a00;
      color: #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: 20px;
      padding-right: 4px;
      line-height: 40px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      vertical-align: middle;
      border-radius: 0;
      min-width: 40px;
    }
    &--logo {
      font-size: 18px;
      margin-bottom: 0;
      line-height: 40px;
      cursor: pointer;
      overflow: hidden;
      width: 100%;
    }
    &--title {
      margin-left: 16px;
    }
  }

  .nav-middle {
    display: flex;
    align-items: center;
    flex: 1 1 0%;
    .anticon-appstore {
      margin-left: 8px;
      cursor: pointer;
      transform: rotate(0);
      transition: transform 0.3s linear;
      &--rotate {
        transform: rotate(45deg);
      }
    }
  }
  // 导航右边
  .nav-right {
    display: flex;
    align-items: center;
    &--username {
      position: relative;
      display: inline-block;
      width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .ant-avatar {
      background-color: #7265e6;
      cursor: pointer;
    }
  }
  .breadcrumb-container {
    margin-left: 16px;
  }
  .collapsed-container,
  .breadcrumb-container {
    cursor: pointer;
  }
}

.sider-menu-wrapper {
  flex: 1 1 0%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  .ant-menu {
    border-right: none;
  }
  .ant-menu-inline-collapsed {
    width: 40px;
    text-align: center;
  }
}
.micro-skeleton {
  padding: 0 32px;
}

.notice-list {
  margin-right: 8px;
  margin-bottom: 6px;
  position: relative;
  padding: 4px 8px;
  padding-right: 24px;
  margin-top: 8px;
  overflow: hidden;
  background-color: #f0f0f0;
  width: 100%;
  &--item {
    white-space: nowrap;
    display: inline-block;
    animation: 10s wordsLoop linear infinite normal;
  }
  &--close {
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 16px;
    height: 14px;
    top: 7px;
    font-size: 12px;
  }
}

.ant-back-top {
  right: 16px;
}
.spread-menu {
  position: absolute;
  top: 40px;
  left: 200px;
  bottom: 0;
  min-width: 490px;
  max-width: 680px;
  overflow: hidden;
  background-color: #fff;
  z-index: 5000;
  opacity: 0;
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.16);

  &--container {
    // width: 100%;
    overflow: hidden auto;
    height: 100%;
    flex: 1 1 0;
    padding: 16px;
    font-size: 12px;
    a {
      text-decoration: none;
      color: #666;
      outline: none;
      border-image: initial;
      border-radius: 2px;
      text-align: left;
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 32px;
      line-height: 30px;
      padding: 0 8px;
      &:hover {
        background-color: #f4f6f7;
        color: #333;
      }
    }
  }
  &--category {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
    break-inside: avoid;
  }
  &--body {
    columns: 180px 3;
    column-gap: 12px;
  }
  &--content {
    position: relative;
    transition: all 300ms ease-in-out 0s;
  }

  [class^='level-'] {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    font-weight: 600;
    transition: all 250ms linear 0s;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 8px;
    -webkit-font-smoothing: auto;
  }
  .level-1--title {
    color: #ff6a00;
  }
}

@keyframes wordsLoop {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@-webkit-keyframes wordsLoop {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@ant-prefix: oilcloud;@menu-item-padding: 0 16px;@menu-collapsed-width: 40px;@font-family: "PingFang SC, 'Microsoft YaHei',MicoOpenSans-Regular, OpenSans, PingFangSC-Regular";@text-color: #454e61;