// 主题色1
@brand1-1: #e6f7ff;
@brand1-6: #0c85ee;
@brand1-8: #004ba1;

// 主题色1
@brand2-1: #e6ffee;
@brand2-6: #03c666;
@brand2-1: #00a156;

// 线条颜色
@line1-1: #dfe5ed;
@line1-2: #d7dce8;
@line1-3: #b7becd;
@line1-4: #747f93;

@fill1-1: #f1f3f7;
@fill1-2: #eef1f4;
@fill1-3: #e2e7ee;
@fill1-4: #cbd2de;

// 背景色
@pannel1-1: #f1f3f7;
@pannel1-2: #eef1f4;
@pannel1-3: #e2e7ee;
@pannel1-4: #cbd2de;

// 文字颜色
@text1-1: #b7becb;
@text1-2: #747f93;
@text1-3: #454e61;
@text1-4: #2a2e3c;

// 功能色 用于信息提示
@notice1-1: #e6f7ff;
@notice1-2: #b0e5ff;
@notice1-3: #0c85ee;
@notice1-4: #004ba1;

// 功能色 用于表示成功
@success1-1: #e6ffee;
@success1-2: #a5fac4;
@success1-3: #03c666;
@success1-4: #007a45;
// 功能色 用于表示警告
@warning1-1: #fff9e6;
@warning1-2: #ffe5a3;
@warning1-3: #ff9500;
@warning1-4: #b35c00;

// 功能色 用于表示错误
@error1-1: #fff2f0;
@error1-2: #ffd1c9;
@error1-3: #f12525;
@error1-4: #a60813;

// 数据色
@data1-1: #ff3d00;
@data1-2: #ff9100;
@data1-3: #ffea00;
@data1-4: #1cda7c;
@data1-5: #00b0ff;
@data1-6: #2979ff;
@data1-7: #651fff;
@data1-8: #d500f9;

// 系统色： 黑 白 透明
@black: #000000;
@white: #ffffff;
@transparent: transparent;

// corner 圆角
@corner-1: 4px;
@corner-2: 8px;
@corner-3: 16px;

// 字体
@font-family: Roboto, 'Helvetica Neue', Helvetica, Tahoma, Arial, 'PingFang SC', 'Microsoft YaHei';

// 行高
@line-height-1: 1.3;
@Line-height-2: 1.5;
@Line-height-3: 1.7;

// 字体尺寸
@Display-3: 64px; // line-height-1（86px）
@Display-2: 56px; // line-height-1（72px）
@Display-1: 36px; // line-height-1（46px）
@Headline: 24px; // line-height-1（32px）
@Title: 20px; // line-height-1（26px）
@Subhead: 16px; // line-height-1（22px）
@Body-1: 14px; // line-height-2（22px）
@Body-2: 12px; // line-height-2（18px）
@Caption: 10px; // line-height-2（15px）

// 粗细
@Bold: bold;
@Regular: normal;
@Light: lighter;

// Shadow 阴影
@Shadow-1-RB: 1px 1px 3px 0 rgba(42, 46, 60, 0.12);
@Shadow-2-RB: 2px 2px 4px 0 rgba(42, 46, 60, 0.12);
@Shadow-3-RB: 3px 3px 5px 0 rgba(42, 46, 60, 0.12);
@Shadow-1-B: 0 1px 3px 0 rgba(42, 46, 60, 0.12);
@Shadow-2-B: 0 2px 4px 0 rgba(42, 46, 60, 0.12);
@Shadow-3-B: 0 3px 5px 0 rgba(42, 46, 60, 0.12);

// Size 尺寸
@base-size: 4px;

// 间距倍数
@space: 4;

@link-color: rgb(12, 133, 238);

@list-hover-color: #fafafa;
@title-font-size: 14px;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  scrollbar-width: thin;
  scrollbar-color: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
ul,
li {
  list-style: none;
  padding: 0;
}
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
.ant-layout {
  height: 100%;
}

// .ant-menu::-webkit-scrollbar-thumb {
//   border-radius: 8px;
//   background-color: rgba(192, 181, 181);
//   -webkit-box-shadow: -2px 0 0 #aaaaaa inset, 1px 0 0 #aaaaaa inset, 0 -1px 0 rgb(255 255 255 / 90%) inset,
//     0 1px 0 rgb(255 255 255 / 90%) inset;
// }
::-webkit-scrollbar {
  width: 8px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset, 0 -1px 0 rgb(255 255 255 / 90%) inset,
    0 1px 0 rgb(255 255 255 / 90%) inset;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
}

@ant-prefix: oilcloud;@menu-item-padding: 0 16px;@menu-collapsed-width: 40px;@font-family: "PingFang SC, 'Microsoft YaHei',MicoOpenSans-Regular, OpenSans, PingFangSC-Regular";@text-color: #454e61;